import React, { useEffect, useState } from 'react';
import HeadBrick from '../HeadBrick/HeadBrick';
import Style from './EndPanelCredits.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import successIcon from '../../icon/success-new.svg';
import AnWerSvg from '../../icon/anwer.svg';
import InlineSVG from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../reducer/action/mainAction';
import classNames from 'classnames';
import FJUtil from '../../util/FJUtil';

let EndPanelCredits = props => {
    let { successType = 'credits' } = props;
    const LIST_ITEM = successType + '-questionnaire-survey-list';
    const EALOG_TYPE = ' ai credits purchase success page reason option';
    const SUCCESS_DESC = successType + '-success-desc';

    const dispatch = useDispatch();

    const method = useSelector(state => state.mainReducer.method);
    const dataInfo = useSelector(state => state.mainReducer.dataInfo);
    const selectPage = useSelector(state => state.mainReducer.selectPage);
    const showQuestionnaire = useSelector(state => state.mainReducer.showQuestionnaire);
    const { info: currentCheckedCreditPackage } = useSelector(state => state.mainReducer.creditsPayData);
    const _close = () => {
        dispatch(mainAction.closeSubscribeView());
        dispatch(mainAction.openOrCloseCreditsPayPanel(false));
        dispatch(mainAction.changeBuyCreditsSuccessPage(false));
    };
    const _handleClickStart = () => {
        if (method === 'popup') {
            _close();
        } else {
            window.location.href = FJLocalStore.getJumpUrl('/editor/');
        }
    };
    const timestampToDate = timestamp => {
        let map = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let date = typeof timestamp === 'string' ? new Date(timestamp) : new Date(timestamp * 1000);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();
        return map[month] + ' ' + day + ', ' + year;
    };

    // 展示积分有效期
    const endDate = () => timestampToDate(dataInfo.period_end);

    // 展示title下的描述文字
    const _descText = () => {
        let text = FJLocalStore._(SUCCESS_DESC);
        text = text.replace('%s', dataInfo.amount);
        text = text.replace('%s', endDate());
        return text;
    };
    const showImage = () => {
        let amount = dataInfo.amount;
        return amount > 0;
    };
    const affiliateURL = () => {
        let coupon = dataInfo.coupon;
        let discount = dataInfo.discount;
        let amount = dataInfo.amount;
        let transactionID = dataInfo.transaction_id;

        if (!transactionID) transactionID = window.fjuser.info.id;
        let url = null;

        if (amount > 0) {
            if (document.domain === 'www.designcap.com' || document.domain === 'designcap.com') {
                url =
                    'https://shareasale.com/sale.cfm?amount=' +
                    amount +
                    '&tracking=' +
                    transactionID +
                    '&transtype=sale&merchantID=79751&storeid=3';
                if (coupon && coupon !== '') {
                    url += '&couponcode=' + coupon;
                }
            } else if (document.domain === 'www.flexclip.com' || document.domain === 'flexclip.com') {
                url =
                    'https://shareasale.com/sale.cfm?amount=' +
                    amount +
                    '&tracking=' +
                    transactionID +
                    '&transtype=sale&merchantID=79751&storeid=1';
                if (coupon && coupon !== '') {
                    url += '&couponcode=' + coupon;
                }

                try {
                    let packageName = selectPage;

                    window.gtag('event', 'conversion', {
                        send_to: 'AW-974294121/8eefCP7Z8LAYEOmYytAD',
                        value: amount,
                        currency: 'USD',
                        transaction_id: transactionID,
                    });

                    window.gtag('event', 'conversion', {
                        send_to: 'AW-974294121/aV0FCM2DhK4YEOmYytAD',
                        value: amount,
                        currency: 'USD',
                        transaction_id: transactionID,
                    });

                    // GA4电子商务
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push({
                        event: 'purchase',
                        ecommerce: {
                            transaction_id: transactionID,
                            affiliation: '',
                            value: amount,
                            currency: 'USD',
                            tax: 0,
                            shipping: 0,
                            items: [
                                {
                                    item_id: packageName,
                                    item_name: packageName,
                                    list_name: packageName,
                                    item_brand: 'FlexClip',
                                    category: 'monthly',
                                    variant: 'monthly',
                                    list_position: 1,
                                    quantity: 1,
                                    price: amount,
                                },
                            ],
                            send_to: ['G-R5WHLT8DF7', 'AW-974294121/d47OCPHyrYAYEOmYytAD'],
                        },
                    });
                    console.log('purchase');
                } catch (e) {}
            }

            if (window.affiliateStatistics) {
                window.affiliateStatistics({ transactionID, amount, coupon, discount, type: 'credit' });
                window.affiliateStatistics = null;
            }
        }

        return url;
    };

    /**
     * 增加订阅成功统计事件
     */
    useEffect(() => {
        if (window.eventLog) {
            window.eventLog.addEvent('revenue', {
                price: window.FJGlobalariable.modSubscription.state.amount,
                plan: currentCheckedCreditPackage.packageName,
                new_renew: 'new',
                platform: window.fj.currentPlan.sub_type,
                coupon: window.FJGlobalariable.modSubscription.state.coupon,
            });

            FJUtil.zgEventLog('支付页面-支付成功', {
                价格: window.FJGlobalariable.modSubscription.state.amount,
                订阅类型: '积分购买',
                套餐类型: currentCheckedCreditPackage.packageName,
                支付平台: window.fj.currentPlan.sub_type,
                折扣码: dataInfo.coupon,
            });
        }
    }, []);

    // 组装列表数据
    const displayData = new Array(8).fill(null).map((item, index) => {
        return {
            id: index + 1,
            content: FJLocalStore._(`${LIST_ITEM}-${index + 1}`),
            selected: false,
        };
    });
    const [items, setItems] = useState(displayData);

    const checkQuestionnaire = id => {
        setItems(prevItems => {
            return prevItems.map(item => {
                if (item.id === id) {
                    return { ...item, selected: !item.selected };
                } else {
                    return item;
                }
            });
        });
    };

    const _commit = () => {
        // 通过提交埋点数据的方式，将用户为什么购买的原因记录
        let submitChooseReasonAndEalog = languageData => {
            let trueItemsId = items.filter(item => item.selected).map(item => item.id);
            let obj = {};
            // 从本地化中将用户选择原因上传到埋点中
            const itemKey = new Array(8).fill('').map((item, index) => `${LIST_ITEM}-${index + 1}`);
            itemKey.forEach(item => {
                obj[item] = languageData[item] || item;
            });

            for (let i = 0; i < trueItemsId.length; i++) {
                window.ealog &&
                    window.ealog.addEvent(EALOG_TYPE, {
                        val: obj[`${LIST_ITEM}-${trueItemsId[i]}`],
                    });
            }
            _handleClickStart();
        };

        FJLocalStore.getYouWantLanguage('en', data => {
            submitChooseReasonAndEalog(data);
        });
    };

    const goBack = () => {
        dispatch(mainAction.closeSubscribeView());
        dispatch(mainAction.openOrCloseCreditsPayPanel(false));
        dispatch(mainAction.changeBuyCreditsSuccessPage(false));
    };

    return (
        <div className={Style.success_box}>
            <HeadBrick
                classname={Style.head}
                style={method === 'popup' ? {} : { display: 'none' }}
                closeClick={_close}
            />
            <div className={Style.successful_content_box}>
                <div className={Style.icon}>
                    <InlineSVG src={successIcon} />
                </div>
                <div className={Style.title}>{FJLocalStore._(`${LIST_ITEM}-title`)}</div>
                <p className={Style.info}>{_descText()}</p>
            </div>
            {showQuestionnaire ? (
                <div className={Style.questionnaire}>
                    <div className={Style.questionnaire_t}>{FJLocalStore._(`${LIST_ITEM}-info`)}</div>
                    <div className={Style.questionnaire_lists}>
                        {items.map(item => {
                            return (
                                <div
                                    key={item.id}
                                    className={classNames(Style.questionnaire_list, {
                                        [Style.selected]: item.selected,
                                    })}
                                    onClick={() => {
                                        checkQuestionnaire(item.id);
                                    }}
                                >
                                    <div className={Style.checkbox}>
                                        {item.selected ? <InlineSVG src={AnWerSvg} /> : ''}
                                    </div>
                                    <div className={Style.questionnaire_list_c}>{item.content}</div>
                                </div>
                            );
                        })}
                    </div>

                    <div
                        className={Style.button}
                        onClick={() => {
                            _commit();
                        }}
                    >
                        {FJLocalStore._('BUTTON_GET_START_1')}
                    </div>
                    {showImage() && <img src={affiliateURL()} width='1' height='1' alt='' />}
                </div>
            ) : (
                <>
                    <div
                        className={Style.button}
                        onClick={() => {
                            goBack();
                        }}
                    >
                        {FJLocalStore._('BUTTON_GET_START_1')}
                    </div>
                    {showImage() && <img src={affiliateURL()} width='1' height='1' alt='' />}
                </>
            )}
        </div>
    );
};
export default EndPanelCredits;
